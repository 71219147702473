import { useState, useEffect } from "react";
import axios from "axios";
import successImg from "../../assets/img/checklist.png";
import { ReactTitle } from "react-meta-tags";
import { useHistory } from "react-router";
import { formatPrice } from '../../helper';

const Pending = () => {
  let history = useHistory();

  const queryParams = new URLSearchParams(window.location.search);
  const orderId = queryParams.get("order_id");
  const pdf = queryParams.get("pdf");

  const [orderDetail, setOrderDetail] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const getOrderDetail = async () => {
      setLoading(true);
      try {
        let response = await axios.get(
          `${process.env.REACT_APP_API_URL}/v1/payment/instant-finish/${orderId}`
        );
        if (orderId !== null) {
          if (!response.data.error) {
            setOrderDetail(response.data.results.detail_order);
            setLoading(false);
          } else {
            history.push('/error');
          }
        } else {
          setOrderDetail("");
          setLoading(false);
          history.push('/error');
        }
      } catch (err) {
        setLoading(true);
        return err.message;
      }
    };
    getOrderDetail();

    // Pixel Fire
    orderDetail.total_price && window.fbq('track', 'Purchase', { currency: "IDR", value: orderDetail?.total_price });

  }, [history, orderId, orderDetail.total_price]);

  return (
    <main id="Pending">
      <ReactTitle title="Pending - Codepolitan Payment" />
      <div className="container-fluid">
        <div className="row" style={{ minHeight: "100vh" }}>
          <div className="col-lg-7">
            <div className="py-5 px-lg-5">
              <h1 className="main-title">Codepolitan Payment</h1>
              <div className="row mt-5 mb-3">
                <div className="col-2">
                  <img className="img-fluid" src={successImg} alt="Sukses" />
                </div>
                <div className="col-auto">
                  <label className="mt-lg-3">
                    {loading ? "Loading..." : 'Order id: ' + orderId}
                  </label>
                  <h3 className="header-title">
                    <strong>
                      {loading ? "Loading..." : 'Hi ' + orderDetail.guest_name + '!'}
                    </strong>
                  </h3>
                </div>
              </div>
              <div className="row mb-3">
                <div className="col">
                  <div className="card">
                    <div className="card-body">
                      <h4 className="card-title">
                        Terimakasih atas Pesanannya!
                      </h4>
                      <p className="card-text">
                        Pesanan kakak sudah kami terima nih. Yuk segera selesaikan pembayarannya, supaya paket kakak segera diaktifkan. Panduan pembayaran sudah kami kirimkan ke email kakak <span className="text-info">{orderDetail.guest_email}</span>
                      </p>
                      {pdf !== "undefined" && (
                        <a className="btn btn-warning btn-sm" href={pdf} target="_blank" rel="noopener noreferrer">Unduh Pdf</a>
                      )}
                      <p className="card-text mt-5">Terimakasih, ditunggu ya.</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row mb-3 d-none d-lg-block">
                <div className="col">
                  <a
                    className="btn btn-primary btn-lg px-4"
                    href="https://codepolitan.com/library"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Lihat Produk Lain
                  </a>{" "}
                  <span className="ms-3">
                    Butuh bantuan?{" "}
                    <a className="link" href="https://wa.me/6285174469460">
                      Hubungi kami
                    </a>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-5 bg-light border-start border-top border-2">
            <div className="py-5 px-lg-5">
              <div className="row">
                <div className="col-3">
                  {loading ? (
                    "Loading..."
                  ) : (
                    <img
                      className="img-fluid rounded"
                      src={orderDetail.product_image}
                      alt={orderDetail.product_name}
                    />
                  )}
                </div>
                <div className="col-9">
                  <h3 className="product-title">
                    {loading ? "Loading..." : orderDetail.product_name}
                  </h3>
                  <p>
                    Tipe: {loading ? "Loading..." : orderDetail.product_type}
                  </p>
                </div>
              </div>
              <hr />
              <div className="row g-3">
                <div className="col">
                  <p>Harga</p>
                  <p>Diskon</p>
                </div>
                <div className="col text-end">
                  <p>
                    Rp.{" "}
                    {loading
                      ? "Loading..."
                      : formatPrice(orderDetail.retail_price) || "0"}
                  </p>
                  <p>
                    Rp.{" "}
                    {loading
                      ? "Loading..."
                      : formatPrice(orderDetail.discount) || "0"}
                  </p>
                </div>
              </div>
              <hr />
              <div className="row">
                <div className="col">
                  <p>Total</p>
                </div>
                <div className="col text-end">
                  <p>
                    Rp.{" "}
                    <span className="total-price">
                      {loading
                        ? "Loading..."
                        : formatPrice(orderDetail.total_price) || "0"}
                    </span>
                  </p>
                </div>
              </div>
              <div className="row mb-3 d-block d-lg-none">
                <div className="col-auto">
                  <div className="d-grid">
                    <a
                      className="btn btn-primary btn-lg px-4"
                      href="https://codepolitan.com/library"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Lihat Produk Lain
                    </a>
                  </div>
                </div>
                <div className="col-auto mt-3">
                  <p>
                    Butuh bantuan?{" "}
                    <a className="link" href="https://wa.me/6285174469460">
                      Hubungi kami
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default Pending;
