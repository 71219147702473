import { ReactTitle } from "react-meta-tags";

const Notfound = () => {
  return (
    <main id="NotFound">
    <ReactTitle title="404 Not Found - Codepolitan Payment"/>
      <div className="container">
        <div className="d-flex justify-content-center align-items-center vh-100">
          <div className="row">
            <div className="col text-center">
              <h1 className="section-title">Ups, Sepertinya kamu tersesat</h1>
              <p className="lead mb-5">
                Klik tombol dibawah ini untuk kembali ke Codepolitan
              </p>
              <a
                className="btn btn-primary px-5 py-3 rounded-pill"
                href="https://codepolitan.com"
              >
                Kembali
              </a>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default Notfound;
