import { Switch, Route } from 'react-router-dom';
import Home from '../pages/Home/Home';
import Finish from '../pages/Finish/Finish';
import Pending from '../pages/Pending/Pending';
import Notfound from '../pages/Error/Notfound';
import Error from '../pages/Error/Error';
import Cancel from '../pages/Cancel/Cancel';
import ProductNotAvailable from '../pages/ProductNotAvailable';

const Routes = () => {
    return(
        <Switch>
            <Route exact path="/" component={Home} />
            <Route path="/status/finish" component={Finish} />
            <Route path="/status/pending" component={Pending} />
            <Route path="/status/error" component={Error} />
            <Route path="/status/cancel" component={Cancel} />
            <Route path="/status/product-not-available" component={ProductNotAvailable} />
            <Route path="*" component={Notfound} />
        </Switch>
    );
}

export default Routes;