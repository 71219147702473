import { ReactTitle } from 'react-meta-tags';

const ProductNotAvailable = () => {
    return (
        <main>
        <ReactTitle title="Sorry,  this Product is not Available - Codepolitan Payment"/>
        <div className="container">
          <div className="d-flex justify-content-center align-items-center vh-100">
            <div className="row">
              <div className="col text-center">
                <h1 className="section-title">Maaf Produk yang kamu cari tidak tersedia</h1>
                <p className="lead mb-5">
                  Klik tombol dibawah ini untuk mencari produk lainnya
                </p>
                <a
                  className="btn btn-primary px-5 py-3 rounded-pill"
                  href="https://codepolitan.com/library"
                >
                  Cari Produk Lain
                </a>
              </div>
            </div>
          </div>
        </div>
        </main>
    )
}

export default ProductNotAvailable;
