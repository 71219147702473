import { useState, useEffect, useRef } from "react";
import axios from "axios";
import { useHistory } from "react-router-dom";
import imgPlaceholder from "../../assets/img/placeholder.jpg";
import { ReactTitle } from "react-meta-tags";
import { formatPrice } from "../../helper";
import { FaThumbsUp } from "react-icons/fa6";

const Home = () => {
  let history = useHistory();

  //Get Data Product
  const [productDetail, setProductDetail] = useState([]);
  const [discountCoupon, setDiscountCoupon] = useState([]);
  const [status, setStatus] = useState("");
  const [alert, setAlert] = useState("");

  // Post Data Payment
  const [fullname, setFullname] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [coupon, setCoupon] = useState("");
  const [slug, setSlug] = useState("");

  const [loading, setLoading] = useState(false);
  const [loadingCouponCheck, setLoadingCouponCheck] = useState(false);

  const urlParams = new URLSearchParams(window.location.search);
  const ref = urlParams.get('ref');
  const couponCode = urlParams.get('coupon');

  // Flag to run the URL-based coupon code logic only once
  const initialCouponApplied = useRef(false);

  const hasRunTtq = useRef(false);

  useEffect(() => {
    if (!hasRunTtq.current) {
      if (window.ttq) {
        window.ttq.track('AddToCart');
      } else {
        console.error('TikTok Pixel not initialized');
      }
      hasRunTtq.current = true;
    }
  }, []);

  useEffect(() => {
    const getProductDetail = async () => {
      window.fbq('track', 'AddToCart');

      const queryParams = new URLSearchParams(window.location.search);
      const slugParam = queryParams.get("slug");

      setLoading(true);
      try {
        let response = await axios.get(
          `${process.env.REACT_APP_API_URL}/v1/product/detail/${slugParam}`
        );
        if (slugParam !== null) {
          if (!response.data.error) {
            setProductDetail(response.data);
            setSlug(slugParam);
            setLoading(false);
          } else {
            history.push("/error");
          }
        } else {
          setProductDetail("");
          setLoading(false);
          history.push("/error");
        }
      } catch (err) {
        setLoading(true);
        return err.message;
      }
    };
    getProductDetail();
  }, [history]);

  useEffect(() => {
    if (couponCode && !initialCouponApplied.current) {
      setCoupon(couponCode);
      initialCouponApplied.current = true;
    };
  }, [couponCode]);

  const handleCouponCheck = async () => {
    setLoadingCouponCheck(true);
    try {
      let response = await axios.get(`${process.env.REACT_APP_API_URL}/v1/coupon/check/${coupon}/${slug}`);
      if (response.data.status === "success") {
        let discount = null;
        if (response.data.results.type === "nominal") {
          discount = response.data.results.nominal;
        } else {
          discount = Math.floor(
            (productDetail.retail_price * response.data.results.nominal) / 100
          );
        }
        setLoadingCouponCheck(false);
        setStatus("Yeay, kupon berhasil diterapkan! Kupon ini hanya berlaku selama satu hari.");
        setDiscountCoupon(discount);
      } else {
        setStatus(response.data.message);
        setLoadingCouponCheck(false);
        setDiscountCoupon(0);
      }
    } catch (error) {
      console.error("Error occurred while checking coupon:", error);
    }
  };

  const handleCheckout = async (e) => {
    e.preventDefault();
    setLoading(true);
    let response = await axios({
      method: "post",
      url: `${process.env.REACT_APP_API_URL}/v1/payment/instant-process`,
      headers: {
        "Content-type": "application/json",
        Accept: "*/*",
      },
      data: JSON.stringify({
        guest_name: fullname,
        guest_email: email,
        guest_whatsapp: phone,
        coupon_code: coupon,
        product_slug: slug,
        ref: ref
      }),
    });

    if (response.data.status === "success") {
      setLoading(false);
      window.snap.pay(response.data.results.snap_token, {
        onSuccess: (result) => {
          //  window.location = site_url + 'status/finish?order_id=' + result.order_id;
          history.push(`/status/finish?order_id=${result.order_id}`);
        },
        onPending: (result) => {
          // window.location = site_url + 'payment/mid/pending/' + result.order_id + '?pdf=' +result.pdf_url;
          history.push(
            `/status/pending?order_id=${result.order_id}&pdf=${result.pdf_url}`
          );
        },
        onError: (result) => {
          // window.location = site_url + 'payment/mid/error/' + data.order_code;
          history.push(`/status/error?order_id=${result.order_id}`);
        },
        onClose: () => {
          // window.location = site_url + 'payment/mid/cancel/' + data.order_code;
          history.push(
            `/status/cancel?order_id=${response.data.results.order_code}`
          );
        },
      });
      setFullname("");
      setEmail("");
      setPhone("");
      setCoupon("");
      setDiscountCoupon(0);
    } else {
      setAlert(response.data.message);
      setLoading(false);
      setDiscountCoupon(0);
    }
  };

  const totalPrice = productDetail?.retail_price - discountCoupon;

  if (productDetail?.retail_price <= 0) {
    history.push('/status/product-not-available');
  }

  return (
    <main id="Home">
      <ReactTitle title="Checkout - Codepolitan Payment" />
      <div className="container-fluid">
        <form onSubmit={handleCheckout}>
          <div className="row" style={{ minHeight: "100vh" }}>
            <div className="col-lg-7">
              <div className="py-5 px-lg-5">
                <h1 className="main-title">Codepolitan Payment</h1>
                <div className="row mt-5">
                  <div className="col-lg-6">
                    <h2 className="header-title">Informasi Pelanggan</h2>
                  </div>
                  <div className="col-lg-6">
                    <p className="text-lg-end mt-1">
                      Lengkapi form di bawah ini
                    </p>
                  </div>
                </div>
                <div className="mb-5">
                  <label htmlFor="email" className="form-label">
                    Email
                  </label>
                  <input
                    onChange={(e) => setEmail(e.target.value)}
                    value={email}
                    type="email"
                    className="form-control form-control-md"
                    id="email"
                    placeholder="Contoh: john@gmail.com"
                    required
                  />
                </div>
                <h2 className="header-title">Nama dan Nomor Whatsapp</h2>
                <div className="mb-3 mt-3">
                  <label htmlFor="fullname" className="form-label">
                    Nama Lengkap
                  </label>
                  <input
                    onChange={(e) => setFullname(e.target.value)}
                    value={fullname}
                    type="text"
                    className="form-control form-control-md"
                    id="fullname"
                    placeholder="Contoh: John Doe"
                    required
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="phone" className="form-label">
                    Nomor Whatsapp
                  </label>
                  <input
                    onChange={(e) => setPhone(e.target.value)}
                    value={phone}
                    type="tel"
                    pattern="[0-9]*"
                    className="form-control form-control-md"
                    id="phone"
                    placeholder="Contoh: 085720xxxxxx"
                    required
                  />
                </div>
                <div className="mb-3">
                  <p><strong>Note:</strong> Pastikan email dan nomor whatsapp yang kamu masukkan adalah benar. Sebab kami akan gunakan data tersebut untuk mengirimkan voucher aktivasi paket belajar kamu atau melakukan konfirmasi terkait pembayaran.</p>
                  <p>Khusus kamu yang melakukan pembayaran Bootcamp, kaos, merchandise, tidak perlu melakukan aktivasi voucher, kami akan langsung memproses pesanan kamu.</p>
                </div>
              </div>
            </div>
            <div className="col-lg-5 bg-light border-top border-start border-2">
              <div className="py-5 px-lg-5">
                <div className="row">
                  <div className="col-3">
                    <img
                      className="img-fluid rounded"
                      src={productDetail.product_image || imgPlaceholder}
                      alt={productDetail.product_title || "Not Found"}
                    />
                  </div>
                  <div className="col-9">
                    <h2 className="h5 fw-bold">
                      <span className="me-2">{productDetail.product_name || "Not Found"}</span>
                      {productDetail?.flag && (
                        <span className="badge rounded-pill bg-primary d-inline-flex align-items-center gap-2">
                          <FaThumbsUp />
                          {productDetail?.flag}
                        </span>
                      )}
                    </h2>
                    <p className="text-capitalize mb-1">Tipe: {productDetail.product_type || "-"}</p>
                    {productDetail?.product_desc && (
                      <p>{productDetail?.product_desc}</p>
                    )}
                  </div>
                </div>

                <hr />

                <div className="d-flex justify-content-between gap-3 w-100">
                  <input
                    onChange={(e) => setCoupon(e.target.value.toUpperCase())}
                    value={coupon.toUpperCase()}
                    type="text"
                    className="form-control form-control-md"
                    id="coupon"
                    placeholder="Kode Kupon (Jika ada)"
                  />
                  <button
                    id="btnCouponCheck"
                    type="button"
                    onClick={handleCouponCheck}
                    className="btn btn-primary btn-md"
                    disabled={!coupon || loadingCouponCheck}
                  >
                    Check
                  </button>
                </div>
                <small className="text-danger">{status}</small>

                <hr />

                <div className="row g-3">
                  <div className="col">
                    <p>Harga</p>
                    <p>Diskon</p>
                  </div>
                  <div className="col text-end">
                    <p>Rp. {formatPrice(productDetail.retail_price) || "0"}</p>
                    <p>Rp. {formatPrice(discountCoupon) || "0"}</p>
                  </div>
                </div>
                <hr />
                <div className="row">
                  <div className="col">
                    <p>Total</p>
                  </div>
                  <div className="col text-end">
                    <p>
                      Rp.{" "}
                      <span className="total-price">
                        {slug !== "" ? formatPrice(totalPrice) : "0"}
                      </span>
                    </p>
                  </div>
                </div>
                <div className="d-grid gap-2 my-3">
                  <button
                    type={productDetail && !loading ? "submit" : "button"}
                    className={`btn btn-lg ${productDetail ? "btn-primary" : "btn-secondary"}`}
                    disabled={!productDetail || loading}
                  >
                    {loading ? (
                      <>
                        <span
                          className="spinner-border spinner-border-sm me-2"
                          role="status"
                          aria-hidden="true"
                        ></span>
                        Please wait...
                      </>
                    ) : (
                      "Checkout"
                    )}
                  </button>
                </div>
                {loading && (
                  <div className="alert alert-warning">
                    <p>Mohon tunggu, pesanan kakak sedang kami proses. Selanjutnya akan muncul pilihan pembayaran pada halaman ini. Jika terdapat masalah, silahkan ulangi pesanan atau kontak CS kami</p>
                    <a className="btn btn-success btn-sm" href="https://wa.me/628999488990" target="_blank" rel="noopener noreferrer"><i className="fab fa-fw fa-whatsapp" /> Chat Putri</a>
                  </div>
                )}
                {!loading && alert && (
                  <div className="alert alert-danger">
                    <p className="text-danger mb-0">{alert}</p>
                  </div>
                )}
              </div>
            </div>
          </div>
        </form>
      </div>
    </main>
  );
};

export default Home;
